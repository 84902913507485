<template>
  <div class="row">
    <div class="tabs">
      <div class="side_nav_wrapper">
        <div class="__side_nav">
          <button
            v-if="!disallowedUsers"
            class="btn"
            :class="[
              $route.path.includes('/admin/promo_codes')
                ? '__side_item_active'
                : '',
            ]"
            @click="$router.replace('/admin/promo_codes').catch((err) => {})"
          >
            Promos
          </button>
          <button
            class="btn"
            :class="[
              $route.path == '/admin/referrals' ? '__side_item_active' : '',
            ]"
            @click="$router.replace('/admin/referrals').catch((err) => {})"
          >
            Referrals
          </button>
        </div>
      </div>
    </div>
    <div class="col-md-12">
      <div class="promo_top_container">
        <div class="promo_header">
          <div class="header___wrapper">
            <h2>Promo Code Overwiew</h2>
            <button @click="createPromoCode">
              <img src="../../../assets/add_promo.svg" alt="img" /> Create New
              Coupon
            </button>
          </div>
        </div>
        <div class="__promo_overview">
          <div class="overview_container _first___">
            <p class="description">Total promo code used</p>
            <p class="amount">
              {{ totalPromoCodesUesd }}
            </p>
          </div>
          <div class="overview_container">
            <p class="description">Total assigned promo code</p>
            <p class="amount">
              {{ tableData.length }}
            </p>
          </div>
        </div>
      </div>
      <ComponentLoader v-if="loading" :loading="loading" />
      <div class="__main_card_container" v-else>
        <div class="_main_card_header">
          <div class="__header_inner admin_header_padding">
            <h2>
              {{ $route.meta.header }}
            </h2>
            <div class="side_nav_wrapper">
              <div class="__side_nav">
                <button
                  class="btn"
                  :class="[
                    $route.path == '/admin/promo_codes/all'
                      ? '__side_item_active'
                      : '',
                  ]"
                  @click="
                    $router.replace('/admin/promo_codes/all').catch((err) => {})
                  "
                >
                  All
                </button>
                <button
                  class="btn"
                  :class="[
                    $route.path == '/admin/promo_codes/active'
                      ? '__side_item_active'
                      : '',
                  ]"
                  @click="
                    $router
                      .replace('/admin/promo_codes/active')
                      .catch((err) => {})
                  "
                >
                  Active
                </button>
                <button
                  class="btn"
                  :class="[
                    $route.path == '/admin/promo_codes/inactive'
                      ? '__side_item_active'
                      : '',
                  ]"
                  @click="
                    $router
                      .replace('/admin/promo_codes/inactive')
                      .catch((err) => {})
                  "
                >
                  Inactive
                </button>
                <button
                  class="btn"
                  :class="[
                    $route.path == '/admin/promo_codes/expired'
                      ? '__side_item_active'
                      : '',
                  ]"
                  @click="
                    $router
                      .replace('/admin/promo_codes/expired')
                      .catch((err) => {})
                  "
                >
                  Expired
                </button>
              </div>
            </div>
          </div>
        </div>
        <div class="main_card_body">
          <router-view></router-view>
        </div>
      </div>
    </div>
    <createPromoCode ref="createpromocode" />
  </div>
</template>

<script>
import moment from "moment";
import $ from "jquery";
import store from "@/state/store.js";
import createPromoCode from "./createPromocode.vue";
export default {
  name: "admin-resellers",
  components: {
    createPromoCode,
  },
  data() {
    return {
      loading: false,
    };
  },
  methods: {
    createPromoCode() {
      let element = this.$refs.createpromocode.$el;
      $(element).modal("show");
    },
  },
  computed: {
    totalPromoCodesUesd() {
      return store.getters["admin/promocodes/totalPromoCodesUesd"];
    },
    tableData() {
      return store.getters["admin/promocodes/promoCodes"];
    },
    disallowedUsers() {
      return this.$store.getters["auth/disallowedUsers"];
    },
  },
  mounted() {
    this.loading = true;
    store
      .dispatch("admin/promocodes/getPromoCodes")
      .finally(() => (this.loading = false));
  },
};
</script>

<style lang="scss" scoped>
.promo_top_container {
  background-color: #fff !important;

  .__promo_overview {
    display: flex;
    margin-top: 7px;

    .overview_container {
      padding: 27px 40px;
    }

    ._first___ {
      border-right: 2px solid #f3f3f4;
    }

    .description {
      font-family: "Graphik Regular";
      font-style: normal;
      font-weight: normal;
      font-size: 12px;
      line-height: 13px;
      color: #8892a2;
      margin-bottom: 10px;
    }

    .amount {
      font-family: "Graphik Regular";
      font-style: normal;
      font-weight: 600;
      font-size: 30px;
      line-height: 33px;
      color: #040a1d;
      margin-bottom: 0px;
    }
  }

  .promo_header {
    width: 100%;
    height: 62px;
    border-bottom: 1px solid #f3f3f4;

    .header___wrapper {
      width: 100%;
      height: 62px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding-right: 1em;
      padding-left: 1em;

      h2 {
        font-family: "Graphik Regular";
        font-style: normal;
        font-weight: 500;
        font-size: 18px;
        line-height: 20px;
        text-transform: capitalize;
        color: #575761;
      }

      button {
        width: 199px;
        height: 47px;
        background: #f7921c;
        border-radius: 6px;
        outline: none;
        border: none;
        font-family: "Graphik Regular";
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 15px;
        display: flex;
        align-items: center;
        text-align: right;
        color: #ffffff;
        padding-right: 15px;
        padding-left: 15px;

        img {
          margin-right: 18px;
        }
      }
    }
  }
}

.__main_card_container {
  margin-top: 20px;
}

.tabs {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  border-bottom: 1px solid #e5e5e5;
  padding-bottom: 1rem;
}
</style>
